const jobs = {
  swengineer: {
    linkedin: '',
    banner: 'https://images.pexels.com/photos/943096/pexels-photo-943096.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
    bannerBottom: 'https://image.shutterstock.com/image-photo/know-your-role-written-on-600w-1140059909.jpg',
    language: 'en'
  },
  chatbotdev: {
    linkedin: '',
    banner: 'https://cdn.pixabay.com/photo/2019/01/16/20/52/chatbot-3936760_1280.jpg',
    bannerBottom: 'https://image.shutterstock.com/image-photo/know-your-role-written-on-600w-1140059909.jpg',
    language: 'pt'
  },
  uxdesigner: {
    linkedin: '',
    banner: 'https://images.pexels.com/photos/196644/pexels-photo-196644.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
    bannerBottom: 'https://images.pexels.com/photos/196644/pexels-photo-196644.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
    language: 'pt'
  }
};

export default jobs;
