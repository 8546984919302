import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import WithLayout from './WithLayout';

import {
  Main as MainLayout
} from './layouts';

import {
  NotFound as NotFoundView,
} from './views-template/supportingPages';

import 'assets/styles/normalize.css';
import 'assets/styles/index.css';

import Index from 'views/Index';
import JobListView from 'views/Jobs/JobListView';
import JobView from 'views/Jobs/JobView';
import ProfileView from 'views/Consultants/ProfileView';
import PromotionView from 'views/Promotion';
import ChatbotsPromotionView from 'views/Chatbots';
import PartnersView from 'views/Partners';
import CasesView from 'views/Cases';

import PrivacyView from 'views/PrivacyView';

const Routes = () => {
  return (
    <Switch>
      <Route path="/consultants/:consultant" exact render={(matchProps) => (
        <WithLayout
          {...matchProps}
          component={ProfileView}
          layout={MainLayout}
        />
      )} />

      <Route path="/jobs" exact render={(matchProps) => (
        <WithLayout
          {...matchProps}
          component={JobListView}
          layout={MainLayout}
        />
      )} />

      <Route path="/jobs/:job" exact render={(matchProps) => (
        <WithLayout
          {...matchProps}
          component={JobView}
          layout={MainLayout}
        />
      )} />

      <Route path="/privacy" exact render={(matchProps) => (
        <WithLayout
          {...matchProps}
          component={PrivacyView}
          layout={MainLayout}
        />
      )} />

      <Route path="/privacy/:lang" exact render={(matchProps) => (
        <WithLayout
          {...matchProps}
          component={PrivacyView}
          layout={MainLayout}
        />
      )} />

      <Route path="/promo" exact render={(matchProps) => (
        <WithLayout
          {...matchProps}
          component={PromotionView}
          layout={MainLayout}
        />
      )} />
      
      <Route path="/chatbots/plans" exact render={(matchProps) => (
        <WithLayout
          {...matchProps}
          component={ChatbotsPromotionView}
          layout={MainLayout}
        />
      )} />

      <Route path="/plans" exact render={() => (
        <Redirect to="/chatbots/plans" />
      )} />

      <Route path="/chatbots/cases" exact render={(matchProps) => (
        <WithLayout
          {...matchProps}
          component={CasesView}
          layout={MainLayout}
        />
      )} />

      <Route path="/cases" exact render={() => (
        <Redirect to="/chatbots/cases" />
      )} />

      <Route path="/chatbots/cases/:id" exact render={(matchProps) => (
        <WithLayout
          {...matchProps}
          component={CasesView}
          layout={MainLayout}
        />
      )} />

      <Route path="/cases/:id" exact render={({ match: { params: { id }} }) => (
        <Redirect to={`/chatbots/cases/${id}`} />
      )} />

      <Route path="/partners" exact render={(matchProps) => (
        <WithLayout
          {...matchProps}
          component={PartnersView}
          layout={MainLayout}
        />
      )} />

      <Route path="/" exact render={(matchProps) => (
        <WithLayout
          {...matchProps}
          component={Index}
          layout={MainLayout}
        />
      )} />

      <Route path="/page-not-found" exact
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={NotFoundView}
            layout={MainLayout}
          />
        )}
      />

      <Redirect to="/page-not-found" />
    </Switch>
  );
};

export default Routes;
