import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import caseList from './cases';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';

import TopImageSection from 'components/TopImageSection';
import PhoneFrame from 'components/PhoneFrame';
import Container from 'common/Container';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import ChatIcon from '@mui/icons-material/Chat';
import LanguageIcon from '@mui/icons-material/Language'; //web
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';

import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';

const style = (theme) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  py: 4,
  px: 2,
  overflowY: 'scroll',
  maxHeight: '90vh',
  maxWidth: '60vw',
  minWidth: 480,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    minWidth: '90%'
  }
});

const stylesDescription = (theme) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '.75rem'
  }
});

class Case {
  constructor(options) {
    this.media = options.media;
    this.logo = options.logo;

    this.case = options.case;
    // {
    //   case.name
    //   case.links = array of [URI, icon]
    //   case.URL
    //   case.clientURL
    //   case.images = array of images
    //   case.channels - expected if category chatbot
    // }

    this.segment = options.segment;   // key must appear in translations.
    this.category = options.category; // chatbot, consulting, platform
  }
}

const cases = caseList.map(c => new Case(c));

function CasesStories({ stories, themeMode }) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [openStory, setStoryOpen] = React.useState(false);
  const [selectedStory, setSelectedStory] = React.useState();
  const handleStoryOpen = (s) => { setStoryOpen(true); setSelectedStory(s); };
  const handleStoryClose = () => setStoryOpen(false);

  const { id } = useParams();

  React.useEffect(() => {
    if (id) {
      const item = stories.find(item => item.case.name === id);

      if (item) {
        handleStoryOpen(item);
      }
    }
  }, []);

  return (
    <>
      { stories.map((item, i) => (
        <Grid item xs={12} sm={6} md={4} key={i}>
          <Box
            display={'block'}
            width={'100%'}
            height={'100%'}
            sx={{
              textDecoration: 'none',
              transition: 'all .2s ease-in-out',
              '&:hover': {
                transform: `translateY(-${theme.spacing(1 / 2)})`,
              },
            }}
          >
            <Box
              component={Card}
              width={'100%'}
              height={'100%'}
              borderRadius={3}
              display={'flex'}
              flexDirection={'column'}
              data-aos={'fade-right'}
            >
              <CardMedia
                image={item.media}
                title={t(`cases.stories.list.${item.case.name}.title`)}
                sx={{
                  height: 240,
                }}
              />
              <Box component={CardContent}>
                <Box maxWidth={100} marginY={2}>
                  { !item.logo ? <></> :
                    <Box
                      component="img"
                      height={'100%'}
                      width={'100%'}
                      src={item.logo}
                      alt="..."
                      sx={{
                        filter: themeMode === 'dark' ? 'contrast(0)' : 'none',
                      }}
                    />
                  }
                </Box>

                <Typography
                  align={'left'}
                  variant={'body2'}
                  color="textSecondary"
                >
                  { t(`cases.stories.list.${item.case.name}.shortDescription`) }
                </Typography>
              </Box>
              <Box flexGrow={1} />
              <Box component={CardActions} justifyContent={'flex-start'}>
                <Button
                  size="large"
                  endIcon={
                    <svg
                      width={16}
                      height={16}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  }
                  onClick={() => handleStoryOpen(item)}
                >
                  { t('cases.stories.action') }
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}

      <Modal
        open={openStory}
        onClose={handleStoryClose}
      >
        { !selectedStory ? <></> :
          <Box sx={style}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item sm>
                <Typography variant="h5">
                  {t(`cases.stories.list.${selectedStory.case.name}.title`)}
                </Typography>

                <Typography variant="h6">
                  <b>Client</b>: {t(`cases.stories.list.${selectedStory.case.name}.clientName`)}
                </Typography>

                <Divider spacing={1}>
                  <Chip label={t(`cases.stories.list.${selectedStory.case.name}.location`)} sx={{ m: 1 }} />
                  <Box component={Chip} mr={2} label={t(`cases.segments.${selectedStory.segment}`)} />
                </Divider>
                
                <Typography variant='body1' sx={stylesDescription}>
                  {t(`cases.stories.list.${selectedStory.case.name}.description`)}
                </Typography>

                <Divider spacing={1} sx={{ mt: 2 }}></Divider>

                {
                  !selectedStory.case.clientURL ? <></> :
                    <Box mt={1}>
                      <Button component={'a'} target="_blank" href={selectedStory.case.clientURL} variant="contained">
                        {t('cases.stories.clientSite')}
                      </Button>
                    </Box>
                }

                {
                  !selectedStory.case.URL ? <></> :
                    <Box mt={1}>
                      <Button component={'a'} target="_blank" href={selectedStory.case.URL} variant="contained" color="info">
                        {t('cases.stories.clientProject')}
                      </Button>
                    </Box>
                }


                {
                  !selectedStory.case.links ? <></> :
                    <>
                      { selectedStory.case.links.map((l,j) => (
                        <Box mt={1} key={j}>
                          <Button component={'a'} target="_blank" href={l[1]} startIcon={l[2]} variant="contained" color="info">
                            {l[0]}
                          </Button>
                        </Box>
                      ))}
                    </>
                }


                {
                  selectedStory.case.channels ?
                    <Divider spacing={1} sx={{ mt: 2 }}>
                      <span>{t('cases.stories.channels')}: </span>
                      <ChannelChips channels={selectedStory.case.channels}></ChannelChips>
                    </Divider>
                    : <></>
                }
              </Grid>
              <Grid item sm={12} md={6} lg={5} xl={4}>
                <PhoneFrame images={selectedStory.case.images} sticky />
              </Grid>
            </Grid>
          </Box>
        }
      </Modal>
    </>
  );
}

function ChannelChips({ channels }) {
  const { t } = useTranslation();

  const chips = channels.map(([channel, link]) => {
    if (channel === 'whatsapp') {
      return { icon: <WhatsAppIcon />, color: 'primary', label: t(`cases.channels.${channel}`), link };
    } else if (channel === 'messenger') {
      return { icon: <FacebookIcon />, color: 'info', label: t(`cases.channels.${channel}`), link };
    } else if (channel === 'site') {
      return { icon: <LanguageIcon />, color: 'secondary', label: t(`cases.channels.${channel}`), link };
    } else if (channel === 'telegram') {
      return { icon: <TelegramIcon />, color: 'info', label: t(`cases.channels.${channel}`), link };
    } else if (channel === 'instagram') {
      return { icon: <InstagramIcon />, color: 'warning', label: t(`cases.channels.${channel}`), link };
    } else if (channel === 'gbm') {
      return { icon: <ChatIcon />, color: 'info', label: t(`cases.channels.${channel}`), link };
    }
  });

  return (
    <>
      {
        chips.map(({ icon, color, label, link }, i) => {
          const chip = <Chip color={color} icon={icon} label={label} key={i} sx={{ ml: i > 0 ? 1 : 0 }} />;

          if (link) {
            return <a href={link} target="_blank" rel="noreferrer" key={i}>{chip}</a>;
          }
          return chip;
        })
      }
    </>
  );
}

export default function CasesView({ themeMode }) {
  const { t } = useTranslation();



  return (
    <>
      <TopImageSection
        imageURI={require('../../assets/img/cases/header.png')}
        padding={25}
        opacity={0.2}
        title={t('cases.title')}
      ></TopImageSection>

      <Container>
        <Box>
          <Box marginBottom={4}>
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'medium',
              }}
              gutterBottom
              color={'secondary'}
              align={'center'}
            >
              { t('cases.stories.title') }
            </Typography>
            <Box
              component={Typography}
              fontWeight={700}
              variant={'h3'}
              align={'center'}
              sx={{
                whiteSpace: 'pre-line'
              }}
            >
              { t('cases.stories.subtitle') }
            </Box>
          </Box>

          <Grid container spacing={4}>
            <CasesStories stories={cases} themeMode={themeMode} />
          </Grid>
        </Box>
      </Container>

      <Container maxWidth="md">
        <Box py={6}
          component={Card}
          data-aos={'zoom-in'}
        >
          <CardContent>
            <Box textAlign='center'>
              <Typography variant={'h5'} fontWeight={600} gutterBottom>
                {t('cases.know-more.plans')}
              </Typography>

              <Box mt={3}>
                <Button component={RouterLink} to="/plans"
                  variant='contained'
                  fontWeight={700}
                  style={{
                    textTransform: 'uppercase'
                  }}
                >
                  {t('navigation.plans')}
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Box>
      </Container>
    </>
  );
}
