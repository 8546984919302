import React from 'react';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

export default [
  {
    media: require('../../assets/img/cases/case1/cover.png'),
    logo: require('../../assets/img/cases/case1/logo.png'),
    case: {
      name: 'usecar',
      links: [['Case @ Mobile Time', 'https://www.mobiletime.com.br/noticias/10/01/2023/usercar-dobra-volume-de-aluguel-de-carros-com-uso-de-chatbot', <AccountBalanceIcon key={0} />], ['Case @ Terra', 'https://www.terra.com.br/byte/usecar-dobra-volume-de-aluguel-de-carros-com-uso-de-chatbot,2880cdd4964219ebf3079f4a1d0c679d5vhkp3co.html', <AddAPhotoIcon key={1} />]],
      clientURL: 'https://www.usecarbrasil.com.br/',
      images: [['image', require('../../assets/img/cases/case1/print.png')]],
      channels: [['whatsapp', 'https://whatsapp.com'], ['site']]
    },
    segment: 'auto',
    category: 'chatbot',
  },
  {
    media: require('../../assets/img/cases/case2/cover.png'),
    logo: require('../../assets/img/cases/case2/logo.png'),
    case: {
      name: 'foreverliss',
      clientURL: 'https://www.foreverliss.com.br/',
      links: [['Case @ Meta', 'https://business.whatsapp.com/resources/success-stories/forever-liss', <AccountBalanceIcon key={0} />]],
      images: [['image', require('../../assets/img/cases/case2/print.png')]],
      channels: [['whatsapp', 'https://whatsapp.com'], ['instagram'], ['messenger'], ['site']]
    },
    segment: 'cosmetico',
    category: 'chatbot',
  },
  {//images: [['iframe', 'https://wpcoder.co.uk/links/mixkit-man-holding-neon-light-1238-large.mp4'], ['image', require('../../assets/img/cases/case1/image1.png')]],
    media: require('../../assets/img/cases/case4/cover.png'),
    logo: require('../../assets/img/cases/case4/logo.png'),
    case: {
      name: 'bagaggio',
      clientURL: 'https://www.bagaggio.com.br',
      images: [['iframe', 'https://player.vimeo.com/video/926921960?background=1&badge=0&autopause=0&player_id=0&app_id=58479']],
      links: [['+Info', 'https://www.linkedin.com/posts/blipbr_pratodosverem-activity-7135628481748492289-RzXB?utm_source=share&utm_medium=member_desktop', <AccountBalanceIcon key={0} />]],
      channels: [['whatsapp', 'https://whatsapp.com']]
    },
    segment: 'commerce',
    category: 'chatbot',
  },
  {//images: [['iframe', 'https://wpcoder.co.uk/links/mixkit-man-holding-neon-light-1238-large.mp4'], ['image', require('../../assets/img/cases/case1/image1.png')]],
    media: require('../../assets/img/cases/case3/cover.png'),
    logo: require('../../assets/img/cases/case3/logo.png'),
    case: {
      name: 'bengflores',
      clientURL: 'https://www.bengflores.com.br',
      images: [['image', require('../../assets/img/cases/case3/print.png')]],
      links: [['+Info', 'https://docs.google.com/presentation/d/1xugJdDSjZVlgC90hM7F2a45cjTjwNaS1eKyGyDS8Ei8/edit?usp=sharing', <AccountBalanceIcon key={0} />]],
      channels: [['whatsapp', 'https://whatsapp.com']]
    },
    segment: 'commerce',
    category: 'chatbot',
  } // bagaggio, gupy, madesa, linda casa, engov
];
